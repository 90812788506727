import { useEffect, useState } from 'react';
import { dbService } from '../../firebase';
import NavbarWhite from '../structure/NavbarWhite';
import styles from './ReservationView.module.css';
import { useParams, useNavigate } from 'react-router-dom';
import {collection, getDocs, query, where } from "firebase/firestore"

function GetData(reservationId, userObj) {
  const navigate = useNavigate();
	const [posts, setPosts] = useState([]);
	const [permit, setPermit] = useState(false);

	// 요일 표시하기
	const reserveDate =new Date(posts.ReservationDate);

	var week = ['일','월','화','수','목','금','토'];
	var dayOfWeek = week[reserveDate.getDay()];
	//
	

	const getPosts = async() =>{

		const q = query(collection(dbService, 'posts'), where("creatorId", "==", userObj.uid));
		const dbposts = await getDocs(q);
		console.log(dbposts);
		dbposts.forEach(document => {				
				const postObject = {
					...document.data(),
					id: document.id,					
				};								
				console.log(document.data());
				if ( userObj.uid === postObject.creatorId && reservationId === document.id) {
						setPosts(postObject);
						setPermit(true);							
				} else {
					
				}
				console.log(postObject.creatorId);
				console.log(document.id)
			}
		);

	};	

	// const onDeleteClick = async () => {
	// 	const ok = window.confirm("게시물을 삭제해도 괜찮으십니까?");
	// 	if(ok) {
	// 		await deleteDoc(doc(dbService, "posts", reservationId));
	// 	}
	// 	navigate('/reservation');
	// };

	useEffect(()=>{
		getPosts();		
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);	

	const item = (
	 
	<>
		{permit ? 
			
			
			<div className={styles.post_wrap}>				

				<div className={styles.page_descript}>
					<p>아래 예약정보를 확인해 주세요</p>
				</div>

				<div className={styles.wrap_title}>
							<h3>예약내용</h3>							
				</div>

				<div className={styles.reserve_wrap}>

						<div className={styles.detail_row}> 
							<p className={styles.detail_sub}>예약날짜</p>
							<p className={styles.detail_cont}>{ posts.ReservationDate }  ({dayOfWeek}) </p>
						</div>	

						<div className={styles.detail_row}> 
							<p className={styles.detail_sub}>예약시간</p>
							<p className={styles.detail_cont}>{posts.time + "시"}</p>
						</div>	
				
						<div className={styles.detail_row}> 
							<p className={styles.detail_sub}>예약인원</p>
							<p className={styles.detail_cont}>{ posts.people + "명"}</p>
						</div>	

						<div className={styles.detail_row}> 
							<p className={styles.detail_sub}>주차여부</p>
							<p className={styles.detail_cont}>{ posts.parking + "대" }</p>
						</div>		

						<div className={styles.detail_row}> 
							<p className={styles.detail_sub}>옵션사항</p>
							<p className={styles.detail_cont}>{ posts.option }</p>
						</div>			

						<div className={styles.detail_row}> 
							<p className={styles.detail_sub}>촬영내용 및 전달사항</p>
							<div className={styles.detail_cont}>
								<p className={styles.detail_sub_cont}>{ posts.post }</p>						
							</div>
						</div>							

				</div>			

				<div className={styles.wrap_title}>
							<h3>예약자 정보</h3>							
				</div>	

				<div className={styles.guest_wrap}>									
				
						<div className={styles.detail_row}> 
							<p className={styles.detail_sub}>업체명(이름)</p>
							<p className={styles.detail_cont}>{ posts.name }</p>
						</div>							

						<div className={styles.detail_row}> 
							<p className={styles.detail_sub}>연락처</p>
							<p className={styles.detail_cont}>{ posts.phoneNumuber }</p>
						</div>						

						<div className={styles.detail_row}> 
							<p className={styles.detail_sub}>이메일(인증)</p>
							<p className={styles.detail_cont}>{ userObj.email }</p>
						</div>						
																					
				</div>	
				{/* <button className={styles.delete_button} onClick={onDeleteClick}>삭제</button> */}
				<button className={styles.back_button} onClick={() => navigate(-1)}>뒤로가기</button>
			</div>
		:
			<div className={styles.secret_wrap}>
				<div className={styles.secret_line}>
					<div className={styles.caution_mark}>!</div>
					<h2 className={styles.secret_word}> 비밀글입니다</h2>
				</div>
				<button className={styles.back_button_02} onClick={() => navigate(-1)}>뒤로가기</button>
				
			</div>
		}
		
	</>
	
	)
	return item;
}

function ReservationView( {userObj} ) {
	const{reservationId} = useParams();
	const item = GetData(reservationId, userObj);

	return (
		<>
		<NavbarWhite />
	
		<div className={styles.container}>
			<div className={styles.page_title_part}>
				<div className={styles.page_title}>
					<h3>RESERVATION DETAILS</h3>						
				</div>
			</div>
								
			<div>					
					{item}
			</div> 
	
		</div>
		</>
	);

}


export default ReservationView;