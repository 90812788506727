//import MobileNaverAPIMap from '../components/MobileNaverMapApi';
import NavbarWhite from '../components/structure/NavbarWhite';
import NaverAPIMap from '../components/NaverMapApi';
import styles from './Address.module.css';
import { Suspense } from 'react';

function Address() {

	return (
		<>
			<NavbarWhite />
			<div className={styles.container}>
				<div className={styles.address_title_part}>
					<div className={styles.address_title}>
						<h3>ADDRESS</h3>						
					</div>
					<div className={styles.address_descript}>
						<p>서울시 관악구 낙성대역4길 3, 4층</p>
						<p><b>(낙성대역 4번 출구 도보 5분)</b></p>
					</div>
				</div>

				<div className={styles.map_wrapper}>
					<a href="https://naver.me/xAtWIxJ9" target="_blank" rel="noopener noreferrer">
						<Suspense fallback={<div>Loading...</div>}>
           					<NaverAPIMap></NaverAPIMap>
						</Suspense>
					</a>
        		</div>
				{/* <div className={styles.m_map_wrapper}>

					 <MobileNaverAPIMap></MobileNaverAPIMap>
				</div> */}

				<div className={styles.parking_part}>
					<p>주차 : 건물 옆 경사면 주차장 or 건물 뒤 주차장 (1대) </p>
					<p>주차 1대가 넘을 경우 추가차량은 <span className={styles.line_change}>도보 5분 거리 낙성대 제2공영주차장을 이용해주세요</span> </p>
				
				</div>
							
			</div>
		</>
	)
}

export default Address;