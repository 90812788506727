import React from "react";
import { NavermapsProvider } from 'react-naver-maps';
import { Container as MapDiv, Marker, NaverMap, useNavermaps } from 'react-naver-maps'



export const NaverAPIMap = (props) => {

  const navermaps = useNavermaps()

  return (
      <NavermapsProvider
        clientId={'ty08pkwidx'}
      >
        <MapDiv 
          style={{
            width: '100%',
            height: '60vw',
            maxHeight: '375px'
        }}>
          <NaverMap
            defaultCenter={new navermaps.LatLng(37.475426, 126.961626)}
            defaultZoom={16}
          >
            <Marker
              defaultPosition={new navermaps.LatLng(37.475426, 126.961626)}
            />
          </NaverMap>
        </MapDiv>  
      </NavermapsProvider>
  );
};


export default NaverAPIMap;