import NavbarWhite from '../components/structure/NavbarWhite';
import styles from './GalleryPart.module.css';
import clsx from 'clsx';
import { Gallery } from "react-grid-gallery";
import {useState} from 'react';
import { images, CustomImage } from './Images';
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

function GalleryPart() {
	const [index, setIndex] = useState(-1);

	const currentImage = images[index];
	const nextIndex = (index + 1) % images.length;
  const nextImage = images[nextIndex] || currentImage;
  const prevIndex = (index + images.length - 1) % images.length;
  const prevImage = images[prevIndex] || currentImage;

	const handleClick = (index: number, item: CustomImage) => setIndex(index);
	const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);

	return (
		<>
			<NavbarWhite />
			<div className={styles.container}>
			<div className={styles.page_title_part}>
					<div className={styles.page_title}>
						<h3>GALLERY</h3>						
					</div>

					{/* <div className={styles.page_descript}>
						<p>아래 [예약신청] 버튼을 통해 원하는 예약날짜와 시간을 신청해 주세요</p>
					</div> */}
				</div>

				<div className={styles.gallery_part}>
					{/* <div className={styles.gallery_detail_part}>
						<div className={styles.gallery_img}>													
							<div className={styles.images}>
								<img src='https://youtu.be/reFpXPPtMlI' alt='youtube' />
							</div>							
							<div className={styles.images}></div>
							<div className={styles.images}></div>
							<div className={styles.images}></div>
						</div>
					</div> */}

					<Gallery 
						images={images}
						onClick={handleClick}
						enableImageSelection={false}
					/>
					{!!currentImage && (
        
						<Lightbox
							mainSrc={currentImage.original}
							imageTitle={currentImage.caption}
							mainSrcThumbnail={currentImage.src}
							nextSrc={nextImage.original}
							nextSrcThumbnail={nextImage.src}
							prevSrc={prevImage.original}
							prevSrcThumbnail={prevImage.src}
							onCloseRequest={handleClose}
							onMovePrevRequest={handleMovePrev}
							onMoveNextRequest={handleMoveNext}
						/>
      		)}

				</div>

				<div className={styles.reserve_button_part}>
					<a href="/reservation" className={clsx(styles.hall_reservation_btn, styles.total_total_btn)}>
						{/* <img src="img/naver-icon.png" alt='naver-icon' /> */}
						<p>예약하기</p>
					</a>
				</div>

			</div>
		
		</>
	)
};

export default GalleryPart;