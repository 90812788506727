import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
//import { getMessaging, getToken } from "firebase/messaging";

const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBSE_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_FIREBSE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBSE_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBSE_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_APP_ID,
	measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

const app = initializeApp(firebaseConfig);
// firebase.initializeApp(firebaseConfig);

export const firebaseInstance = getAuth();

export const dbService = getFirestore(app);

export const authService = getAuth();

// const messaging = getMessaging(app);

// getToken(messaging, {vapidKey: "BAb5F5v6iAHToGnf2ABPxv_gpUUFhtq5BW7qHfENSFqhwH0ShOhlSDO2CBO_Bmd-nFnPzoY0TgNfOPRKty_hzcg"})
// 	.then((currentToken)=> {
// 		if (currentToken) {
// 			console.log('currentToken : ', currentToken);
// 		} else {
// 			console.log('Can not get Token');
// 		}
// 	})



// 	function requestPermission() {
// 		console.log('Requesting permission...');
// 		Notification.requestPermission().then((permission) => {
// 			if (permission === 'granted') {
// 				console.log('Notification permission granted.');
// 			} else {
// 				console.log('Do not have permission');
// 			}
// 		})
// 	}

// 	requestPermission();

