import './Calendar.css'
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import googleCalendarPlugin from '@fullcalendar/google-calendar';


function Calendar(){

	const apiKey = process.env.REACT_APP_CAL_API_KEY;

	return (
		<FullCalendar
						plugins={[ dayGridPlugin, googleCalendarPlugin ]}
						initialView="dayGridMonth"
						googleCalendarApiKey={apiKey}
						events={{
							googleCalendarId: 'tryground100@gmail.com',
						}}
						eventDisplay={''}
						eventTextColor={'#FFF'}
						eventColor={'#F2921D'}
						navLinks={false}							
						eventClick={false}
						contentHeight={580}						
					/>
	)
}

export default Calendar;