import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import CommonTable from '../components/board/CommonTable';
import CommonTableColumn from '../components/board/CommonTableColumn';
import CommonTableRow from '../components/board/CommonTableRow';
import NavbarWhite from '../components/structure/NavbarWhite';
import { dbService } from '../firebase';
import styles from './Reservation.module.css';
import {collection, getDocs, orderBy, query} from "firebase/firestore";
import ReactPaginate from 'react-paginate';

function BoardData( ) {
  const [posts, setPosts] = useState([]);
	
	const getPosts = async()=> {

		const q = query(collection(dbService, 'posts'), orderBy('createdAt', 'asc'));

		const dbposts = await getDocs(q);
		
		const newPost = dbposts.docs.map(doc => ({ ...doc.data(), id: doc.id }));

		setPosts(newPost);
	}

	useEffect(()=>{
			getPosts();		
	}, []);

	const item = (Object.values(posts)).map((item, index)=>(
		
		
			<CommonTableRow key={item.id}>

				
					<CommonTableColumn>{index + 1}</CommonTableColumn>
				
					<Link to={`/reservation/${item.id}`}>
						<CommonTableColumn>예약합니다{item.title}</CommonTableColumn>
					</Link>		
				
					<CommonTableColumn className={styles.confirm}>{item.confirm ? "확정" : "대기"}</CommonTableColumn>		

					<CommonTableColumn className={styles.createdTime}>{item.createdAt}</CommonTableColumn>		
				
				
					<CommonTableColumn>				
						{item.name.length > 4 
							? (item.name.substr(0,3)+"*")
							: (item.name.substr(0,2)+"*")
						}
					</CommonTableColumn>			
				
			</CommonTableRow>
		
	)).reverse();

	return item;

}

function Reservation( {userObj} ) {

	useEffect(() => {
		const src = document.createElement("script");
		src.type = "text/javascript";    
    src.src= '//wcs.naver.net/wcslog.js'
    document.head.appendChild(src);

    const script = document.createElement("script");
    script.innerHTML = `         
				if (!wcs_add) var wcs_add={};
				wcs_add["wa"] = "s_43745e953a43";
				var _nasa={};
				if (window.wcs) {
					_nasa["cnv"] = wcs.cnv("5","1");
					wcs_do(_nasa); 
				}
   	`;
    script.type = "text/javascript";
    script.async = "async";
    document.head.appendChild(script);
  }, []);

	const navigate = useNavigate();
	
	const item = BoardData(userObj);	
	
	// react-paginate
	const PER_PAGE = 10;
	const [currentPage, setCurrentPage] = useState(0);

	function handlePageClick({ selected: selectedPage }) {
    setCurrentPage(selectedPage);		
  };

	const offset = currentPage * PER_PAGE;

  const currentPageData = item.slice(offset, offset + PER_PAGE)
  
  const pageCount = Math.ceil(item.length / PER_PAGE)


	//react-paginate end

	// Search bar

	const [seacrh , setSearch] = useState("");
	
	const handleSubmit = (e) => {
		e.preventDefault();
		navigate(`/search?name=${seacrh}`);
		setSearch('');
	}

	return (
		<>
		<NavbarWhite />

		<div className={styles.container}>
				<div className={styles.page_title_part}>
					<div className={styles.page_title}>
						<h3>RESERVATION</h3>						
					</div>
					<div className={styles.page_descript}>
						<p>아래 [예약신청] 버튼을 통해 원하는 예약날짜와 시간을 신청해 주세요</p>
					</div>
				</div>
				
				<div className={styles.board_wrap}>					
					
					<CommonTable headersName={['No', '제목', '예약여부', '등록일', '작성자']}>						
					 	{currentPageData}																
					</CommonTable>
				</div>	


				{/* react paginate */}
				<ReactPaginate 
					previousLabel={"〈"}
					nextLabel={"〉"}
					pageCount={pageCount}
					onPageChange={handlePageClick}					
					containerClassName={styles.pagination}
					previousLinkClassName={styles.pagination__link}
					nextLinkClassName={styles.pagination__link}
					disabledClassName={styles.pagination__link__disabled}
					activeClassName={styles.pagination__link__active}
					/>
				{/* react-paginate end */}
				{/* <Stack spacing={2}>
      		<Pagination count={10} />
				</Stack> */}
	
				<div className={styles.search_bar}>
					<form onSubmit={handleSubmit} autoComplete="off">
						<input className={styles.search_writter} onChange={(e) => setSearch(e.target.value)} id="search" type="text" defaultValue={seacrh} required placeholder='업체명(이름)..'></input>
						<input className={styles.search_btn} type="submit" value="검색"></input>
					</form>
				</div>

				<div className={styles.naver_button}>
					<a className={styles.naver_reserve_btn} href="https://booking.naver.com/booking/10/bizes/317191/items/3321741" target="_blank" rel="noopener noreferrer"><img src='/img/naver-icon-wh.png' alt='naver-icon'/>네이버 예약</a>
				</div>

				<div className={styles.write_button}>
					<a className={styles.reserve_total_btn} href="/reservation/write" >예약신청</a>
				</div>
				
    </div>

		</>
	);
}

export default Reservation;