import Slider from 'react-slick';
import styles from './Home.module.css';
import './slick.css';
import './slick-theme.css';
import NaverAPIMap from '../components/NaverMapApi';
import { FaChevronRight } from "react-icons/fa";
import Navbar from '../components/structure/Navbar';
import { HashLink } from 'react-router-hash-link';
import clsx from 'clsx';

function Home() {
 
  const settings = { 
    dots: true, 
    infinite: true, 
    speed: 500, 
    slidesToShow: 1, 
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 2000
  };

  const equip_slide = {
    dots: false, 
    infinite: true, 
    speed: 500, 
    slidesToShow: 6, 
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 479,
        settings: { slidesToShow: 1, slidesToScroll: 1, infinite: false }
      }      
    ]
  }

  const option_slide = {
    dots: false, 
    infinite: true, 
    speed: 500, 
    slidesToShow: 1, 
    slidesToScroll: 1,
    arrows: true,    
  }
  
  return (

    <>
      <Navbar />
      <div className={styles.main_image_part}>
        <Slider {...settings}>
          <div className={styles.main_image}></div>
          <div className={styles.main_image_02}></div>
          <div className={styles.main_image_03}></div>
          <div className={styles.main_image_04}></div>
          <div className={styles.main_image_05}></div>
        </Slider>
      </div>

      <div className={styles.main_space_part}>
        <div className={styles.container}>
            
            <div className={styles.space_part_title}>
              <h2>유튜브・제품 촬영을 위한 <span className={styles.change_line}>아늑한 스튜디오</span></h2>
            </div>

            <div className={styles.space_mark}>
              SPACE
            </div>

            <div className={styles.space_wrapper}>
              <div className={styles.space_image_part}>
                <div className={styles.space_image_wrap}>
                  <h3 className={styles.space_concept}>concept 01</h3>
                  <h4 className={styles.space_zone}>화이트 배경의 곡선 계단 ZONE</h4>
                  <HashLink smooth to='/studio#concept1' className={styles.detail_btn_concept1}>                
                      자세히 보기
                  </HashLink>
                </div>
                <div className={styles.space_image_01}></div>                
                
              </div>
              <div className={styles.space_image_part}>
                <div className={styles.space_image_wrap}>
                    <h3 className={styles.space_concept}>concept 02</h3>
                    <h4 className={styles.space_zone}>크림 소파와 원목 베이스 ZONE</h4>
                    <HashLink smooth to='/studio#sofa' className={styles.detail_btn_concept2}>
                      자세히 보기
                    </HashLink>
                </div>
                <div className={styles.space_image_02}></div>          
                
              </div>
              <div className={styles.space_image_part}>
                <div className={styles.space_image_wrap}>
                  <h3 className={styles.space_concept}>concept 03</h3>
                  <h4 className={styles.space_zone}>모던 센추리 느낌의 모듈 가구 ZONE</h4>
                  <HashLink smooth to='/studio#mordern' className={styles.detail_btn_concept3}>
                      자세히 보기
                  </HashLink>
                </div>
                <div className={styles.space_image_03}></div>                
              </div>
              <div className={styles.space_image_part}>
                <div className={styles.space_image_wrap}>
                  <h3 className={styles.space_concept}>concept 04</h3>
                  <h4 className={styles.space_zone}>스튜디오룸 ZONE</h4>
                  <HashLink smooth to='/studio#studioroom' className={styles.detail_btn_concept4}>
                      자세히 보기
                  </HashLink>   
                </div>
                <div className={styles.space_image_04}></div>                                      
              </div>
            </div>

        </div>
      </div>

      <div className={styles.main_reservation_part}>
        <div className={styles.container}>
          <div className={styles.button_wrapper}>
            <a href="/schedule" className={clsx(styles.reservation_button, styles.schedule_button)}>
              {/* <div className={styles.button_icon}>
                <img src="img/naver-icon.png" alt="naver-icon" />
              </div> */}
              <h3>스케줄 확인하기</h3>            
            </a>
            {/* <a href="https://booking.naver.com/booking/10/bizes/317191" target="_blank" rel='noopener noreferrer' className={`${styles.reservation_button} ${styles.naver_total_btn}`}>
              <div className={styles.button_icon}>
                <img src="img/naver-icon.png" alt="naver-icon" />
              </div>
              <h3>예약하기</h3>            
            </a> */}
          </div>
          <div className={styles.address_wrapper}>
            <div className={styles.address_line}>
              <div className={styles.address_front}>
                <h2>OPEN</h2>
              </div>
              <div className={styles.address_back}>
                <p>09:00 ~ 24:00 ( 그 외 시간 별도 문의)</p>
              </div>
            </div> 
            <div className={styles.address_line}>
              <div className={styles.address_front}>
                <h2>PHONE</h2>
              </div>
              <div className={styles.address_back}>
                <p>070-4422-8060 (am09:00~pm09:00)</p>
              </div>
            </div> 
            <div className={styles.address_line}>
              <div className={styles.address_front}>
                <h2>E-MAIL</h2>
              </div>
              <div className={styles.address_back}>
                <p>service@tryground.co.kr</p>
              </div>
            </div> 
            <div className={styles.address_line}>
              <div className={styles.address_front}>
                <h2>ADDRESS</h2>
              </div>
              <div className={styles.address_back}>
                <p>서울시 관악구 낙성대역4길3, 4층</p>
              </div>
            </div>  
          </div>
          <div className={styles.map_wrapper}>
            <a href="https://naver.me/xAtWIxJ9" target="_blank" rel="noopener noreferrer">
              <NaverAPIMap></NaverAPIMap>
            </a>
          </div>         
        </div>
      </div>

      <div className={styles.main_equip_part}>
        <div className={styles.container}>
          <div className={styles.equip_mark}>
            EQUIPMENT
          </div>

          <div className={styles.equip_subtitle}>
            -FREE-
          </div>
        </div>
        
        <div className={styles.equip_img_wrap}>
          <Slider {...equip_slide}>
            <div className={styles.equip_img}>
              <img src='img/free-equip-01.png' alt="equip-img" />
            </div>
            <div className={styles.equip_img}>
              <img src='img/free-equip-02.png' alt="equip-img" />
            </div>
            <div className={styles.equip_img}>
              <img src='img/free-equip-03.png' alt="equip-img" />
            </div>
            <div className={styles.equip_img}>
              <img src='img/free-equip-04.png' alt="equip-img" />
            </div>
            <div className={styles.equip_img}>
              <img src='img/free-equip-05.png' alt="equip-img" />
            </div>
            <div className={styles.equip_img}>
              <img src='img/free-equip-06.png' alt="equip-img" />
            </div>
            <div className={styles.equip_img}>
              <img src='img/free-equip-07.png' alt="equip-img" />
            </div>
            <div className={styles.equip_img}>
              <img src='img/free-equip-08.png' alt="equip-img" />
            </div>
            <div className={styles.equip_img}>
              <img src='img/free-equip-09.png' alt="equip-img" />
            </div>           
          </Slider>
        </div>

        <div className={styles.equip_subtitle}>
            -OPTION-
        </div>

        <div className={styles.option_img_wrap}>
          <div className={styles.option_img}>
            <img src='img/option-img-03.png' alt="option-img" />
          </div>
          <div className={styles.option_img}>
            <img src='img/option-img-01.png' alt="option-img" />
          </div>
          <div className={styles.option_img}>
            <img src='img/option-img-02.png' alt="option-img" />
          </div>
        </div>

        <div className={styles.m_option_img_wrap}>
          <Slider {...option_slide}>
            <div className={styles.option_img}>
              <img src='img/option-img-03.png' alt="option-img" />
            </div>
            <div className={styles.option_img}>
              <img src='img/option-img-01.png' alt="option-img" />
            </div>
            <div className={styles.option_img}>
              <img src='img/option-img-02.png' alt="option-img" />
            </div>
          </Slider>
        </div>

        <a href="/equipment" className={styles.equip_detail_btn}>
          <p>자세히 보기 <FaChevronRight /></p>
        </a>

        
      </div>
    </>
  );
}
export default Home;