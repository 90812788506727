import React, { useState } from "react";
import {
  	createUserWithEmailAndPassword,
  	signInWithEmailAndPassword,
	signInWithPopup,
	GoogleAuthProvider,
	getAuth
} from "firebase/auth";
import NavbarWhite from "../structure/NavbarWhite";
import styles from './Auth.module.css';

const Auth = () => {
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [newAccount, setNewAccount] = useState(true);
	const [error, setError] = useState("");

	const onChange = (event) => {
		const {target : {name, value}} = event;
		if (name === "email") {
			setEmail(value);
		} else if (name === "password") {
			setPassword(value);
		}
	}

	const onSocialClick = async (event) => {
		const {target : {name}} = event;
		let provider;
		if (name === "google") {
			provider = new GoogleAuthProvider();
		}
		const auth = getAuth();
		const data = await signInWithPopup(auth, provider);
		console.log(data);
	}

	const onSubmit = async (event) => {
		
		
		event.preventDefault();
	
		try {
		let data;
				const auth = getAuth();
				console.log(newAccount);
		if (newAccount) {
			data = await createUserWithEmailAndPassword(
			auth,
						email,
			password
			);
					console.log(newAccount);
		} else {
			data = await signInWithEmailAndPassword(
						auth, 
						email, 
						password
					);
					console.log(newAccount);
		}
		console.log(data);
		} catch (error) {
			var errorCode = error.code;
			
			if (errorCode === 'auth/email-already-in-use') {
				setError("해당 이메일은 이미 사용중입니다");
			} if (errorCode === 'auth/wrong-password') {
				setError("비밀번호가 올바르지 않습니다");
			} if (errorCode === 'auth/user-not-found') {
				setError("해당 이메일은 처음 인증이 필요합니다");
			} if (errorCode === 'auth/weak-password') {
				setError("비밀번호는 6글자 이상이어야 합니다.");
			} if (errorCode === 'auth/network-request-failed') {
				setError("네트워크 연결에 실패 하였습니다.");
			} 

			console.log(error.code);
			return ;
		}		
	}

	const toggleAccount = () => setNewAccount((prev)=> !prev);

	return (
		<>
			<NavbarWhite />
			<div className={styles.container}>
					<div className={styles.page_title_part}>
						<div className={styles.page_title}>
							<h3>RESERVATION</h3>						
						</div>
						<div className={styles.page_descript}>
							<p>본인 인증이 필요합니다</p>
						</div>
					</div>

					<div className={styles.authwrap}>
						<div className={styles.authBox}>
							<form className={styles.writeBox} onSubmit={onSubmit}>
								<input type="text" name="email" placeholder="E-mail" value={email} onChange={onChange} required  nblur="this.placeholder='E-mail'" />
								<input type="password" name="password" placeholder="Password" value={password} onChange={onChange} required />
								<div className={styles.errors}>{error}</div>
								<input type="submit" value={ newAccount ? "처음 방문이에요" : "기존 인증하기"} />						
							</form>
							<span onClick={toggleAccount} className={styles.toggleBtn}>{newAccount ? "저번에 인증했어요" : "처음 방문이예요"}</span>

							<div className={styles.google_wrap}>
								<div className={styles.google_wrap_subject}>간편 인증</div>
								<button className={styles.google_login} name="google" onClick={onSocialClick}><img src="/img/google-icon.png" alt="google-icon"></img><p>구글 인증하기</p></button>
							</div>
						</div>
					</div>
			</div>
		</>
	)
};

export default Auth;