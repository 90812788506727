import { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { dbService } from '../../firebase';
import NavbarWhite from '../structure/NavbarWhite';
import CommonTable from './CommonTable';
import CommonTableColumn from './CommonTableColumn';
import CommonTableRow from './CommonTableRow';
import styles from './Search.module.css';
import { Link } from 'react-router-dom';
import { collection, endAt, getDocs, orderBy, startAt, query } from 'firebase/firestore';

function SearchData() {

	const [data, setData] = useState([]);

	const useQuery = () =>{
			return new URLSearchParams(useLocation().search);
	} 

	let queries = useQuery();
	let search = queries.get("name");
	console.log("search", search);

	const getData = async() => {
		
		const q = query(collection(dbService, 'posts'), orderBy('name'), startAt(search),endAt(search + "\uf8ff"));

		const getSearch = await getDocs(q);
		
		// onSnapshot(getSearch, (snapshot) => {
		// 		const searchPosts = [];
		// 		snapshot.forEach((doc) => {
		// 			searchPosts.push(doc.data());
		// 		});

		// 		setData(searchPosts);
		// })

		// getSearch.forEach(doc => {
		// 	const searchPosts = {
		// 		...doc.data(),
		// 		id : doc.id,
		// 	};
		// 	setData(searchPosts);
		// })

		// console.log(data)

		const searchPosts = getSearch.docs.map(doc => ({ ...doc.data(), id: doc.id })).reverse();
		setData(searchPosts)
		

		// console.log(searchPosts);


		// dbService.child("posts").orderByChild("name").equalTo(search).on("value", (snapshot) => {
		// 	if (snapshot.val()) {
		// 		const data = snapshot.val();
		// 		setData(data);
		// 	}
		// })
	}

	useEffect(()=>{
		getData();		
	}, []);

	const item = (Object.values(data)).map((item, index)=>(
		
		<CommonTableRow key={item.id} >
		
			<CommonTableColumn>{index + 1}</CommonTableColumn>
				
			<Link to={`/reservation/${item.id}`}>
				<CommonTableColumn>예약합니다{item.title}</CommonTableColumn>
			</Link>		
			
			<CommonTableColumn>{item.confirm ? "확정" : "대기"}</CommonTableColumn>		
			<CommonTableColumn>{item.createdAt}</CommonTableColumn>		
			<CommonTableColumn>
				{item.name.length > 10 
					? (item.name.substr(0,9)+"*")
					: (item.name)
				}
			</CommonTableColumn>			
			
		
	</CommonTableRow>
	)).reverse();

	return item;

	
};

function Search( ) {
	
	const navigate = useNavigate();
	const item = SearchData();

	console.log(item)

	return (
		<>
		<NavbarWhite />

		<div className={styles.container}>
				<div className={styles.page_title_part}>
					<div className={styles.page_title}>
						<h3>RESERVATION</h3>						
					</div>
					<div className={styles.page_descript}>
						<p>아래 예약신청 버튼을 통해 원하는 예약날짜와 시간을 적어주세요</p>
					</div>
				</div>

				<div className={styles.board_wrap}>		

					{Object.keys(item).length === 0 ? (
						<div className={styles.secret_wrap}>
							
							<div className={styles.secret_line}>
								<div className={styles.caution_mark}>!</div>
								<h2 className={styles.secret_word}> 해당 이름의 예약자가 없습니다</h2>
							</div>														

							<button className={styles.back_button_02} onClick={() => navigate(-1)}>뒤로가기</button>
							
						</div>
					) : (
						<div>
							<CommonTable headersName={[ 'No', '제목', '확정', '등록일', '작성자']}>							
									{item}
							</CommonTable>
							<button className={styles.back_button_01} onClick={() => navigate(-1)}>뒤로가기</button>
						</div>
	
					)}					
					
				</div>									
				
				
    </div>

		</>
	);
}


export default Search;