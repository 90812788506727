import { Image } from "react-grid-gallery";

export interface CustomImage extends Image {
  original: string;
}

export const images: CustomImage[] = [

  {
    src:"./img/gallery-image-35.jpg",
    original:"./img/gallery-image-35.jpg",
    width: 280,
    height: 160,
    caption: "캠퍼스 플러스 4월호"  
  },  
  {
    src:"./img/gallery-image-34.jpg",
    original:"./img/gallery-image-34.jpg",
    width: 280,
    height: 160,
    caption: "어린이가만든어린이 - https://youtu.be/LrHpJet_KHc"  
  },
  {
    src:"./img/gallery-image-33.jpg",
    original:"./img/gallery-image-33.jpg",
    width: 280,
    height: 160,
    caption: "안티에그 - https://antiegg.kr/19616"  
  },
  {
    src:"./img/gallery-image-32.jpg",
    original:"./img/gallery-image-32.jpg",
    width: 280,
    height: 160,
    caption: "나원호 - https://www.youtube.com/watch?v=mF3YYjuureU"  
  },
  {
    src:"./img/gallery-image-31.jpg",
    original:"./img/gallery-image-31.jpg",
    width: 280,
    height: 160,
    caption: "라이브커머스 촬영 - 삼진제약 하루엔진"  
  },
  {
    src:"./img/gallery-image-30.jpg",
    original:"./img/gallery-image-30.jpg",
    width: 280,
    height: 160,
    caption: "팝페라그룹 포엣(POET) -https://www.youtube.com/watch?v=C4gDoRqzQiU"  
  },
  {
    src:"./img/gallery-image-29.jpg",
    original:"./img/gallery-image-29.jpg",
    width: 280,
    height: 160,
    caption: "유튜브 와푸밸 - https://youtu.be/xOVaqcjbp6k"  
  },
  {
    src:"./img/gallery-image-28.jpg",
    original:"./img/gallery-image-28.jpg",
    width: 280,
    height: 160,
    caption: "유튜브 한국상담심리학회 - https://youtu.be/JtV-4nYfB6c"  
  },
  {
    src:"./img/gallery-image-27.jpg",
    original:"./img/gallery-image-27.jpg",
    width: 280,
    height: 160,
    caption: "유튜브 한국상담심리학회 - https://youtu.be/-Jm_oMBfSc0"
  },
  {
    src:"./img/gallery-image-26.jpg",
    original:"./img/gallery-image-26.jpg",
    width: 280,
    height: 160,
    caption: "유튜브 사진찍는 회계사 - https://www.youtube.com/watch?v=7_P8EfaW3VM"
  },
  {
    src:"./img/gallery-image-25.jpg",
    original:"./img/gallery-image-25.jpg",
    width: 280,
    height: 160,
    caption: "유튜브 크리포터 - https://youtu.be/rPYmD6Z7lvI"  
  },
  {
    src:"./img/gallery-image-24.jpg",
    original:"./img/gallery-image-24.jpg",
    width: 280,
    height: 160,
    caption: "유튜브 무모:춤을모으다 - https://youtu.be/BVc6xgMcmVI"  
  },
  {
    src:"./img/gallery-image-22.jpg",
    original:"./img/gallery-image-22.jpg",
    width: 280,
    height: 160,
    caption: "유튜브 무모:춤을모으다 - https://youtu.be/nP1Bpz9YF3k"
  },
  {
    src:"./img/gallery-image-23.jpg",
    original:"./img/gallery-image-23.jpg",
    width: 280,
    height: 160,
    caption: "유튜브 무모:춤을모으다 - https://youtu.be/xjRBdwOx6pc"
  },  
  {
    src:"./img/gallery-image-20.jpg",
    original:"./img/gallery-image-20.jpg",
    width: 280,
    height: 160,
    caption: "유튜브 조작가의 스몰빅클래스 - https://www.youtube.com/live/J_F451sfJwU?feature=share"
  },
  {
    src:"./img/gallery-image-21.jpg",
    original:"./img/gallery-image-21.jpg",
    width: 280,
    height: 160,
    caption: "유튜브 무모:춤을모으다 - https://youtu.be/Wb5yUo2hXNM"  
  },
  {
    src:"./img/gallery-image-18.jpg",
    original:"./img/gallery-image-18.jpg",
    width: 280,
    height: 160,
    caption: "유튜브 조성희의 Mind Power - https://youtu.be/7x3rF2-VhbU"
  },
  {
    src:"./img/gallery-image-19.jpg",
    original:"./img/gallery-image-19.jpg",
    width: 280,
    height: 160,
    caption: "유튜브 이하율 - https://www.youtube.com/watch?v=uBfOU9GegFA&t=7s" 
  },
  {
    src:"./img/gallery-image-17.jpg",
    original:"./img/gallery-image-17.jpg",
    width: 280,
    height: 160,
    caption: "유튜브 KB라스쿨 - https://youtu.be/NLPcEvYuWmo"
  },  
  {
    src:"./img/gallery-image-16.jpg",
    original:"./img/gallery-image-16.jpg",
    width: 280,
    height: 160,
    caption: "유튜브 마이쭈변-주영글 변호사 - https://www.youtube.com/watch?v=gPIHPpaDgq0"    
  },
  {
    src:"./img/gallery-image-15.jpg",
    original:"./img/gallery-image-15.jpg",
    width: 280,
    height: 160,
    caption: "유튜브 마이쭈변-주영글 변호사 - https://www.youtube.com/watch?v=5AH3EI4wqYo"  
  },  
  {
    src:"./img/gallery-image-14.jpg",
    original:"./img/gallery-image-14.jpg",
    width: 280,
    height: 160,
    caption: "유튜브 놀면서 배우는 심리학 - https://youtu.be/dbaom7KASYo"  
  },
  {
    src:"./img/gallery-image-13.jpg",
    original:"./img/gallery-image-13.jpg",
    width: 280,
    height: 160,
    caption: "유튜브 놀면서 배우는 심리학 - https://youtu.be/G-hXY7hydVk"
  },
  {
    src:"./img/gallery-image-12.jpg",
    original:"./img/gallery-image-12.jpg",
    width: 280,
    height: 160,
    caption: "유튜브 저스틴두잇 - https://youtu.be/8-QAxhyeA1U"      
  },
  {
    src:"./img/gallery-image-11.jpg",
    original:"./img/gallery-image-11.jpg",
    width: 280,
    height: 160,
    caption: "유튜브 저스틴두잇 - https://youtu.be/toH2Jzel6Bw"    
  },
  {
    src:"./img/gallery-image-10.jpg",
    original:"./img/gallery-image-10.jpg",
    width: 280,
    height: 160,
    caption: "유튜브 저스틴두잇 - https://youtu.be/e_ZfDMyHNuE"  
  },
  {
    src:"./img/gallery-image-09.jpg",
    original:"./img/gallery-image-09.jpg",
    width: 280,
    height: 160,
    caption: "유튜브 저스틴두잇 - https://youtu.be/xji2V5b7WTI" 
  },
  {
    src:"./img/gallery-image-08.jpg",
    original:"./img/gallery-image-08.jpg",
    width: 280,
    height: 160,
    caption: "유튜브 현대해상 방과후 교실 - https://youtu.be/U3Url3y8td4?list=PL0smXFegCl7zL3WJjQdd51epXPd4zKi5G"  
  },
  {
    src:"./img/gallery-image-07.jpg",
    original:"./img/gallery-image-07.jpg",
    width: 280,
    height: 160,
    caption:"유튜브 와푸밸 - https://youtu.be/qnlCYhxeTZQ"  
  },
  {
    src:"./img/gallery-image-06.jpg",
    original:"./img/gallery-image-06.jpg",
    width: 280,
    height: 160,
    caption:"유튜브 와푸밸 - https://youtu.be/7Gx0V0Y5OuE"  
  },
  {
    src:"./img/gallery-image-05.jpg",
    original:"./img/gallery-image-05.jpg",
    width: 280,
    height: 160,
    caption:"유튜브 와푸밸 - https://youtu.be/SSm3jmdoRmY"  
  },
  {
    src:"./img/gallery-image-04.jpg",
    original:"./img/gallery-image-04.jpg",
    width: 280,
    height: 160,
    caption:"유튜브 서사무엘 - https://youtu.be/TCJVHUOkExg"  
  },
  {
    src:"./img/gallery-image-03.jpg",
    original:"./img/gallery-image-03.jpg",
    width: 280,
    height: 160,
    caption:"유튜브 Bandung Oppa(반둥오빠) - https://youtu.be/0VmP1ewjenI"    
  },
  {
    src:"./img/gallery-image-02.jpg",
    original:"./img/gallery-image-02.jpg",
    width: 280,
    height: 160,
    caption:"유튜브 Bandung Oppa(반둥오빠) - https://youtu.be/E2q428aMA9c"  
  },
  {
    src:"./img/gallery-image-01.jpg",
    original:"./img/gallery-image-01.jpg",
    width: 280,
    height: 160,
    caption:"유튜브 타로문 - https://youtu.be/reFpXPPtMlI"
  },
  
];