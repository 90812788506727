import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { dbService } from '../../firebase';
import {collection, addDoc} from "firebase/firestore"
import NavbarWhite from '../structure/NavbarWhite';
import styles from './WriteReservation.module.css';
import moment from 'moment';

function WriteReservation( {userObj} ) {

	const navigate = useNavigate();

	const [ title, setTitle] = useState("");
	const [ ReservationDate, setReservationDate] = useState("");
	const [ time, setTime] = useState("");
	const [ name, setName] = useState("");
	const [ people, setPeople] = useState("");
	const [ phoneNumuber, setPhoneNumber] = useState("");
	const [ post, setPost] = useState("");
	const [ parking, setParking] = useState("");
	const [ option, setOption] = useState("");
	// eslint-disable-next-line no-unused-vars
	const [ confirm, setconfirm] = useState(false);

	const onSubmit = async (event) => {
		event.preventDefault();	
		await addDoc(collection(dbService, "posts"), {
			title,
      		ReservationDate,
			time,
			name,
			people,
			phoneNumuber,
			parking,
			option,
			post,
			confirm,
      createdAt: moment().format('YYYY-MM-DD'),
			creatorId: userObj.uid,
			userEmail : userObj.email
		})	
    
		
		navigate('/reservation');
	}

	const onChange = (e) => {
		const {id , value} = e.target;
		if(id === "title"){		
			setTitle('예약합니다');
		}
		if(id === "ReservationDate"){
			setReservationDate(value);
		}
		if(id === "time"){
			setTime(value);
		}
		if(id === "name"){
				setName(value);
		}
		if(id === "people"){
				setPeople(value);
		}
		if(id === "phone-number"){
				setPhoneNumber(value);
		}
		if(id === "parking"){
			setParking(value);
		}
		if(id === "option"){
			setOption(value);
		}
		if(id === "post"){
			setPost(value);
		};
	}

	return (
		<>
			<NavbarWhite />

			<div className={styles.container}>
				<div className={styles.page_title_part}>
					<div className={styles.page_title}>
						<h3>RESERVATION</h3>						
					</div>
					<div className={styles.page_descript}>
						<p>아래 [예약신청] 버튼을 통해 원하는 예약날짜와 시간을 적어주세요</p>
					</div>
				</div>		

				<div className={styles.guide_wrap}>
					<div className={styles.guide_part}>
						<p>작성하신 내용은 수정이 불가능하니 [예약신청] 버튼을 클릭하기 전, 한 번 더 확인 부탁드립니다.</p>
						<p>예약 글 작성 후 12시간 이내에 입금이 확인 되어야 예약이 확정됩니다.</p>
						<p>예약 변경 및 취소는 유선(070-4422-8060) 또는 카카오채널 '트라이그라운드 스튜디오'를 통해서만 가능합니다.</p>
						<p>촬영 2일 전 취소 시 결제 금액 100% 환불, 촬영 1일 전 취소 시 결제 금액 50% 환불,
						당일 취소 및 변경 시에는 환불이 어려운 점 참고 부탁드립니다.</p>
					</div>
				</div>
			
				<div className={styles.form_wrap}>
					<form onSubmit={onSubmit} autoComplete="off">
						<div className={styles.input_row}> 
							<p className={styles.input_sub}>제목</p>
							<p className={styles.input} type="text" id='title'>예약합니다.</p>
						</div>
						
						<div className={styles.input_row}> 
							<p className={styles.input_sub}>예약날짜</p>
							<input className={styles.input_cont} type="date" id="ReservationDate" onChange = {(e) => onChange(e)}  required placeholder='ex. 2022/10/11' defaultValue={ReservationDate}></input>
						</div>

						<div className={styles.input_row}> 
							<p className={styles.input_sub}>예약시간</p>
							<input className={styles.input_cont} type="text" id="time" onChange = {(e) => onChange(e)} required placeholder='ex. 17 ~ 21시' defaultValue={time}></input>
							<p className={styles.input_detail}>*분 단위로 예약이 불가능하며, 시간 단위로만 예약이 가능합니다.</p>
						</div>

						<div className={styles.input_row}> 
							<p className={styles.input_sub}>업체명(이름)</p>
							<input className={styles.input_cont} onChange = {(e) => onChange(e)} type="text" id="name" required placeholder='업체명(이름)' defaultValue={name}></input>				
						</div>						

						<div className={styles.input_row}> 
							<p className={styles.input_sub}>인원</p>
							<input className={styles.input_cont} onChange = {(e) => onChange(e)} type="number" placeholder='ex. 6명' required id="people" defaultValue={people}></input>
							<p className={styles.input_detail}>*기본 인원은 5명으로, 추가 인원 1인 1시간에 5,000원 추가요금이 발생합니다.</p>
						</div>

						<div className={styles.input_row}> 
							<p className={styles.input_sub}>주차여부</p>
							<input className={styles.input_cont} onChange = {(e) => onChange(e)} type="number" placeholder='ex. 2대' required id="parking" defaultValue={parking}></input>
							<p className={styles.input_detail}>*주차는 1대까지 가능하며, 2대 이상 시 공영주차장을 이용해 주셔야 합니다.</p>
						</div>

						<div className={styles.input_row}> 
							<p className={styles.input_sub}>연락처</p>
							<input className={styles.input_cont} type="tel" onChange = {(e) => onChange(e)} id="phone-number" placeholder='010-xxxx-xxxx' required defaultValue={phoneNumuber}></input>
						</div>

						<div className={styles.input_row}> 
							<p className={styles.input_sub}>옵션 추가여부</p>
							<input className={styles.input_cont} type="text" onChange = {(e) => onChange(e)} id="option" placeholder='ex. 카메라 2대, SD카드 2개' defaultValue={option}></input>
							<p className={styles.input_detail}>*비용은 카메라 1대 15,000원 SD카드 1개 5,000원이며, 조명과 삼각대는 무료로 이용가능합니다.</p>
						</div>

						{/* <div className={styles.input_row}> 
							<h3 className={styles.input_sub}>예상견적</h3>
							<h3> 120,000원</h3>
						</div> */}

						<div className={styles.input_row}> 
							{/* <input type="text" placeholder='업체명(이름):<br>인원:' onChange = {(e) => onChange(e)} id="post" defaultValue={post}></input> */}
							<textarea type="text" placeholder='촬용 내용 및 전달 사항을 적어주세요' onChange = {(e) => onChange(e)} id="post" defaultValue={post}></textarea>
						</div>

						<div className={styles.back_button}>
							<button onClick={() => navigate(-1)}>뒤로가기</button>
						</div>

						<div className={styles.write_button}>
							<input className={styles.reserve_finish_btn} type='submit' value="예약신청"></input>
						</div>

					</form>

				</div>
			</div>

			{/* 전환페이지 설정 script */}
			<script type="text/javascript" src="//wcs.naver.net/wcslog.js"></script> 
			<script type="text/javascript"> 
				var _nasa={};
				if(window.wcs) _nasa["cnv"] = wcs.cnv("1","10"); 
			</script> 
		</>
	);

}

export default WriteReservation;