import NavbarWhite from '../components/structure/NavbarWhite';
import styles from './StudioRoom.module.css';
import clsx from 'clsx';

function StudioRoom() {

	return (
		<>
			<NavbarWhite />
			<div className={styles.container}>
				<div className={styles.pageTitle_part}>
					<div className={styles.page_title}>
						<h2>스튜디오룸 <b>1시간 19,000원</b></h2>
					</div>
					<div className={styles.page_detail}>
						<div className={styles.page_detail_line}>
              <div className={styles.page_detail_front}>
                <h3>기본 인원</h3>
              </div>
              <div className={styles.page_detail_back}>
                <p>3명 (추가인원 1인 5,000원)</p>
              </div>
            </div> 
            <div className={styles.page_detail_line}>
              <div className={styles.page_detail_front}>
                <h3>무료 장비 대여</h3>
              </div>
              <div className={styles.page_detail_back}>
                <p>전문 촬영조명(룩스패드 43H), 삼각대, 기타 소품</p>							
              </div>
            </div> 
            <div className={styles.page_detail_line}>
              <div className={styles.page_detail_front}>
                <h3>유료 장비 대여</h3>
              </div>
              <div className={styles.page_detail_back}>
                <p>카메라 최대3대, 무선 마이크, SD카드</p>
              </div>
            </div> 
					</div>
				</div>
				<div className={styles.space_detail_wrap}>
					<div className={styles.space_name}>						
						<div className={styles.space_title}>스튜디오룸 ZONE</div>
					</div>
					<div className={styles.space_detail}>
						<p>완벽 방음이 가능한 분리된 공간이에요.<br />
						크로마키 천도 준비되어 있으니 배경 그래픽을<br />
						넣어야 하는 촬영까지 무리 없이 진행할 수 있습니다.<br />
						교육, 홍보, 음악, 프로필 영상 촬영과 화상면접 용도로 많이 사용하세요.
						</p>						
					</div>
					<div className={styles.room_space_img}>
						<img src={require('../assets/room-space-03.png')} alt='room-space-03' />						
						<img src={require('../assets/room-space-04.png')} alt='room-space-04' />						
					</div>
					
				</div>			

				<div className={styles.reserve_button_part}>
					<a href="/reservation" className={clsx(styles.room_reservation_btn, styles.room_room_btn)}>						
						<p>예약하기</p>
					</a>
				</div>	
			</div>
		
		</>
	)
};

export default StudioRoom;