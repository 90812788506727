import NavbarWhite from '../components/structure/NavbarWhite';
import styles from './Equip.module.css';

function Equip() {

	return (
		<>
			<NavbarWhite />
			<div className={styles.container}>
				<div className={styles.space_detail_wrap}>				
					<div className={styles.space_detail}>
						<p>트라이그라운드 스튜디오는<br />
							 영상 및 사진 촬영 장비가 없는 분들을 위해<br />
							 전문 촬영 장비들을 대여해 드리고 있습니다.<br />
							 장비 세팅이 처음인 분들에게는<br />
							 촬영 세팅법 및 간단한 사용법도 설명해 드리고 있으니,<br />
							 부담 없이 오셔서 멋진 작업물 만들어 가시길 바래요.<br />
						</p>				

						<h3>※ 장비 대여는 예약과 함께 사전에 신청해 주셔야 합니다 ※</h3>		
					</div>					
				</div>	
				<div className={styles.pageTitle_part}>
					<div className={styles.page_title}>
						<h2><b>무료 대여</b> 서비스 3가지</h2>
					</div>
					<div className={styles.page_detail}>
						<div className={styles.page_detail_line}>
              <div className={styles.page_detail_front}>
                <h3>01</h3>
              </div>
              <div className={styles.page_detail_middle}>
                <p>전문 촬영조명 7개</p>
              </div>
							<div className={styles.page_detail_middle_back}>
                <p>ㆍ포르자 60 LED(2개)</p>
								<p>ㆍ포맥스 E400(2개)</p>
								<p>ㆍ룩스패드 43H(3개)</p>
              </div>
            </div> 
            <div className={styles.page_detail_line}>
              <div className={styles.page_detail_front}>
                <h3>02</h3>
              </div>
              <div className={styles.page_detail_back}>
                <p>삼각대 3개</p>							
              </div>
            </div> 
            <div className={styles.page_detail_line}>
              <div className={styles.page_detail_front}>
                <h3>03</h3>
              </div>
              <div className={styles.page_detail_back}>
                <p>그 외 다양한 소품들 제공</p>
              </div>
            </div> 
					</div>
				</div>
				<div className={styles.room_space_img}>
						<img src='img/equip-free-01.png' alt='equip-free-01'></img>
						<img src='img/equip-free-02.png' alt='equip-free-02'></img>
						<img src='img/equip-free-03.png' alt='equip-free-03'></img>
						<img src='img/equip-free-04.png' alt='equip-free-04'></img>
						<img src='img/equip-free-05.png' alt='equip-free-05'></img>
				</div>

				<div className={styles.pageTitle_part}>
					<div className={styles.page_title}>
						<h2><b>전문 촬영 장비</b> 유료 대여 서비스</h2>
					</div>
					<div className={styles.page_detail}>
						<div className={styles.page_detail_line}>
              <div className={styles.page_detail_front}>
                <h3>01</h3>
              </div>
              <div className={styles.page_detail_back}>
                <p>카메라 (최대 2개 대여 가능) 각 15,000원</p>
              </div>
							<div className={styles.page_detail_bottom}>
                <p>ㆍSONY A6500, EOS M6 Mark2</p>								
              </div>
            </div> 
            <div className={styles.page_detail_line}>
              <div className={styles.page_detail_front}>
                <h3>02</h3>
              </div>
              <div className={styles.page_detail_back}>
                <p>카메라용 무선 마이크 15,000원</p>							
              </div>
							<div className={styles.page_detail_bottom}>
                <p>ㆍCOMICA BoomX-D (마이크2개, 수신기 1개)</p>								
              </div>
            </div> 
            <div className={styles.page_detail_line}>
              <div className={styles.page_detail_front}>
                <h3>03</h3>
              </div>
              <div className={styles.page_detail_back}>
                <p>핸드폰용 무선 마이크 10,000원</p>							
              </div>
							<div className={styles.page_detail_bottom}>
                <p>ㆍBOYALINK (마이크2개, 수신기 1개)</p>								
              </div>
            </div> 
            <div className={styles.page_detail_line}>
              <div className={styles.page_detail_front}>
                <h3>04</h3>
              </div>
              <div className={styles.page_detail_back}>
                <p>SD카드 각 5,000원</p>
              </div>
            </div> 
					</div>
				</div>
				<div className={styles.equip_space_img}>
					<img src='img/equip-pay-01.png' alt='equip-pay-01'></img>
					<img src='img/equip-pay-02.png' alt='equip-pay-02'></img>
				</div>

							
			</div>
		</>
	)
}

export default Equip;