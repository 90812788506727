import React from 'react';
import ReactDOM from 'react-dom/client';
import './style.css';
import App from './App';
//import { RenderAfterNavermapsLoaded } from "react-naver-maps";
import { NavermapsProvider } from 'react-naver-maps';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';


console.log(firebase);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <NavermapsProvider ncpClientId={"ty08pkwidx"}>
      <App />
    </NavermapsProvider>  
);

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById("root")
// );

