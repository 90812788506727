import React, { useState } from 'react';
import { Outlet } from "react-router";
import { Link, NavLink } from 'react-router-dom';
import './Navbar.css';

function Navbar() {

	const [click, setClick] = useState(false);
    // const [open, setOpen] = useState(false);

    // const handleOpen = () => {
    //     setOpen(!open);

    //     return false;
    // } 
    const handleClick = () => {
        setClick(!click);

        return false;
    }
    const closeMobileMenu = () => setClick(false);

    return (
        <>
        <nav className = 'navbar'>
            <div className = 'navbar-container'>
                <Link to='/' className='navbar-logo'>
                    TRYGROUND studio                  
                </Link>
                <div className='navbar-sns'>
                    <a href='https://blog.naver.com/tryground/222756745495' target="_blank" rel="noopener noreferrer"><img src='img/sns-blog.png' alt='naver-blog-icon'></img></a>
                    <a href='https://www.instagram.com/tryground.studio'  target="_blank" rel="noopener noreferrer"><img src='img/sns-insta.png' alt='insta-icon'></img></a>
                    <a href='https://www.youtube.com/channel/UCeB5QsWqR5TVNu7pA6rLl7A' target="_blank" rel="noopener noreferrer"><img src='img/sns-youtube.png' alt='youtube-icon-02'></img></a>
                </div>
                <ul className='nav-menu'>
                    <li className='nav-item'>
                        <NavLink to='/studio' className='nav-links' activeclassname='is-active' onClick = {closeMobileMenu}>
                            STUDIO
                        </NavLink>
                        {/* <div to="" className='nav-links'>STUDIO</div>
                        <ul className="nav-re">
                            <li className="nav-re-item">
                                <NavLink to='/studio' activeclassname='active' onClick = {closeMobileMenu}>전체대관</NavLink>
                            </li>						
                            <li className="nav-re-item">
                                <NavLink to='/studioroom' activeclassname='active' onClick = {closeMobileMenu}>스튜디오룸</NavLink>
                            </li>						
                        </ul> */}
                    </li>
                    <li className='nav-item'>
                        <NavLink to='/equipment' className='nav-links' activeclassname='is-active' onClick = {closeMobileMenu}>
                            EQUIPMENT
                        </NavLink>
                    </li>
                    <li className='nav-item'>
                        <NavLink to='/gallery' className='nav-links' activeclassname='is-active' onClick = {closeMobileMenu}>
                            GALLERY
                        </NavLink>
                    </li>
                    <li className='nav-item'>
                        <NavLink to='/reservation' className='nav-links' activeclassname='is-active'onClick = {closeMobileMenu}>
                            RESERVATION  
                        </NavLink>
                    </li>
                    <li className='nav-item'>
                        <NavLink to='/schedule' className='nav-links' activeclassname='is-active'onClick = {closeMobileMenu}>
                            SCHEDULE
                        </NavLink>
                    </li>                    
                    <li className='nav-item'>
                        <NavLink to='/address' className='nav-links' activeclassname='is-active'onClick = {closeMobileMenu}>
                            ADDRESS
                        </NavLink>
                    </li>
                </ul>

                <ul className={click ? 'm-nav-menu active' : 'm-nav-menu'}>
                    <div className='white-space'>
                        <li className='m-nav-item'>
                            <NavLink to='/studio' className='m-nav-links' activeclassname='is-active' onClick = {closeMobileMenu}>
                                STUDIO
                            </NavLink>
                            {/* <div className='m-nav-links'  activeclassname='is-active' onClick={handleOpen}>STUDIO</div>
                            <ul className={open ? 'm-nav-re active' : 'm-nav-re' }>
                                <li className="m-nav-re-item">
                                    <NavLink to='/studio' activeclassname='active' onClick = {closeMobileMenu}>전체대관</NavLink>
                                </li>						
                                <li className="m-nav-re-item">
                                    <NavLink to='/studioroom' activeclassname='active' onClick = {closeMobileMenu}>스튜디오룸</NavLink>
                                </li>						
                            </ul> */}
                        </li>
                        <li className='m-nav-item'>
                            <NavLink to='/equipment' className='m-nav-links' activeclassname='is-active' onClick = {closeMobileMenu}>
                                EQUIPMENT
                            </NavLink>
                        </li>
                        <li className='m-nav-item'>
                            <NavLink to='/gallery' className='m-nav-links' activeclassname='is-active' onClick = {closeMobileMenu}>
                                GALLERY
                            </NavLink>
                        </li>
                        
                        <li className='m-nav-item'>
                            <NavLink to='/reservation' className='m-nav-links' activeclassname='is-active'>
                                RESERVATION
                            </NavLink>
                        </li>
                        <li className='m-nav-item'>
                            <NavLink to='/schedule' className='m-nav-links' activeclassname='is-active'>
                                SCHEDULE
                            </NavLink>
                        </li>                        
                        <li className='m-nav-item'>
                            <NavLink to='/address' className='m-nav-links' activeclassname='is-active'>
                                ADDRESS
                            </NavLink>
                        </li>
                    </div>

                    {/* <div className='x-icon' onClick={handleClick}>
                        <i className = {'fas fa-times'}></i>
                    </div>     */}

                </ul>

                <div className='menu-icon' onClick={handleClick}>
                    <i className = { click ? 'fas fa-times' : 'fa fa-light fa-bars'}></i>
                </div>                

            </div>
        </nav>
        <Outlet />
        </>
    );
}

export default Navbar