import NavbarWhite from '../components/structure/NavbarWhite';
import styles from './Studio.module.css';
import clsx from 'clsx';

function Studio() {

	return (
		<>
			<NavbarWhite />
			<div className={styles.container}>
				<div className={styles.pageTitle_part}>
					<div className={styles.page_title}>
						<h2>전체대관(30평) <b>1시간 39,000원</b></h2>
						<p className={styles.page_title_event}>
							* <b>정기 멤버쉽 할인 상품</b> 하단 참고 *
						</p>
					</div>
					<div className={styles.page_detail}>
						<div className={styles.page_detail_line}>
              <div className={styles.page_detail_front}>
                <h3>기본 인원</h3>
              </div>
              <div className={styles.page_detail_back}>
                <p>5명 (추가인원 1인 5,000원)</p>							
              </div>
            </div> 
            <div className={styles.page_detail_line}>
              <div className={styles.page_detail_front}>
                <h3>무료 장비 대여</h3>
              </div>
              <div className={styles.page_detail_back}>
                <p>전문 촬영조명(포르자60, 포맥스 E400, 룩스패드 43H),</p>
								<p>삼각대, 기타 소품</p>
              </div>
            </div> 
            <div className={styles.page_detail_line}>
              <div className={styles.page_detail_front}>
                <h3>유료 장비 대여</h3>
              </div>
              <div className={styles.page_detail_back}>
                <p>카메라 최대3대, 무선 마이크, SD카드</p>
              </div>
            </div> 
					</div>
				</div>
				<div className={styles.space_detail_wrap}>
					<div className={styles.studio_map_wrap}>
						<div className={styles.studio_map}>
							<img src='img/studio-map.png' alt='studio-map'></img>
						</div>

					</div>

					<div id='concept1'></div>
					<div>
						<div className={styles.space_name}>
							<div className={styles.space_mark}>컨셉01</div>
							<div className={styles.space_title}>화이트 배경의 곡선 계단 ZONE</div>
						</div>
						<div className={styles.space_detail}>
							<p>화이트 톤의 깔끔한 공간이에요.<br />
								곡선형 계단과 아치 배경을 활용해<br />
								세련된 이미지를 연출할 수 있답니다.<br />
								인터뷰, 라이브 커머스, <span className={styles.line_change}>제품 촬영으로 많이 사용합니다.</span>
							</p>						
						</div>
						<div className={styles.space_img}>
							<img src='img/white-space-01.png' alt='white-space-01'></img>
							<img src='img/white-space-02.png' alt='white-space-02'></img>
							<img src='img/white-space-03.png' alt='white-space-03'></img>						
						</div>
					</div>

					<div id='sofa'>
						<div className={styles.space_name} id='sofa'>
							<div className={styles.space_mark} id='sofa'>컨셉02</div>
							<div className={styles.space_title}>크림 소파와 원목 베이스 ZONE</div>
						</div>
						<div className={styles.space_detail}>
							<p>내 집 같은 편안한 분위기에<br />
								다양한 소품까지 활용할 수 있는 공간이에요.<br />
								감각적인 가구들과 함께<br />
								아늑하면서도 독특한 이미지를 연출해 보세요.
							</p>						
						</div>
						<div className={styles.space_img}>
							<img src='img/sofa-space-01.png' alt='sofa-space-01'></img>
							<img src='img/sofa-space-02.png' alt='sofa-space-02'></img>
							<img src='img/sofa-space-03.png' alt='sofa-space-03'></img>
							<img src='img/sofa-space-04.png' alt='sofa-space-04'></img>
						</div>
					</div>

					<div id='mordern'>
						<div className={styles.space_name}>
							<div className={styles.space_mark}>컨셉03</div>
							<div className={styles.space_title}>모던 센추리 느낌의 모듈 가구 ZONE</div>
						</div>
						<div className={styles.space_detail} id="concept3">
							<p>힙한 컬러가 포인트인 공간이에요.<br />
								채도 높은 코발트 블루를 사용해<br />
								인물과 제품이 보다 선명해 보이는 효과가 있답니다.<br />
								색다른 분위기를 연출해 보세요.
							</p>						
						</div>
						<div className={styles.space_img}>
							<img src='img/blue-space-01.png' alt='blue-space-01'></img>
							<img src='img/blue-space-02.png' alt='blue-space-02'></img>
							<img src='img/blue-space-03.png' alt='blue-space-03'></img>
						</div>
					</div>

					<div id='studioroom'>
						<div className={styles.space_name} id='studioroom'>
							<div className={styles.space_mark} id='studioroom'>컨셉04</div>
							<div className={styles.space_title}>스튜디오룸 ZONE</div>
						</div>
						<div className={styles.space_detail}>
							<p>완벽 방음이 가능한 분리된 공간이에요.<br />
								크로마키 천도 준비되어 있으니 배경 그래픽을<br />
								넣어야 하는 촬영까지 무리 없이 진행할 수 있습니다.<br />
								교육, 홍보, 음악, 프로필 영상 촬영과 <span className={styles.line_change}>화상면접 용도로 많이 사용하세요.</span><br />
								*전체대관 시 파우더룸으로 사용 가능*
							</p>						
						</div>
						<div className={styles.space_img}>
							<img src='img/room-space-01.png' alt='room-space-01'></img>
							<img src='img/room-space-02.png' alt='room-space-02'></img>						
						</div>
					</div>	
				</div>

				{/* <div className={styles.membership_part}>
					<div className={styles.membership_img}>
						<img src="img/membership-cost.png" alt='membership-cost'></img>
					</div>
				</div> */}

				<div className={styles.reserve_button_part}>
					<a href="/schedule" className={clsx(styles.hall_reservation_btn, styles.total_total_btn)}>
						{/* <img src="img/naver-icon.png" alt='naver-icon' /> */}
						<p>예약하기</p>
					</a>
				</div>

			</div>
		
		</>
	)
};

export default Studio;