import NavbarWhite from '../components/structure/NavbarWhite';
import styles from './Schedule.module.css';
import Calendar from '../components/Calendar';

function Schedule() {

	

	return (
		<>
			<NavbarWhite />			
			<div className={styles.container}>
				<div className={styles.reserve_title_part}>
					<div className={styles.reserve_title}>
						<h3>SCHEDULE</h3>						
					</div>
					<div className={styles.reserve_descript}>						
						<p><span className={styles.orange}>ORG</span> 은 확정된 예약 시간이 표시됩니다</p>
					</div>
				</div>

				<div className={styles.reserve_calendar_part}>			
					<Calendar />							
				</div>

				<div className={styles.reserve_button_part}>
					<a href="/reservation" className={styles.go_reservation_btn}>						
						예약하기
					</a>
				
				</div>

				{/* <div className={styles.reserve_detail_part}>
					<p>견적서 및 세금계산서 발행 필요 시, <span className={styles.line_change}><b>아래 이메일</b>로 예약 사항을 남겨주세요.</span> <br /> <b>( 일자, 시작/종료시간, 인원, 옵션, 이름, 연락처 )</b></p>	
					<p>E-mail : gmldls420@naver.com</p>
					<p>계좌번호 : 국민 206001 04 177882 트라이그라운드 스튜디오</p>
				</div> */}

							
			</div>
		</>
	)
}

export default Schedule;