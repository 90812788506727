import React from 'react';
import styles from './Footer.module.css';

function Footer() {
    
    return (
        <>
					<div className={styles.footer_wrap}>
						<div className={styles.container}>
							<div className={styles.footer_top_part}>
								<div className={styles.address_part}>
									<h2>TRYGROUND studio</h2>
									<p>상호명 : 트라이그라운드 렌탈스튜디오</p>
									<p>사업자등록번호 : 296-48-00525</p>
									<p>통신판매업신고번호 : 2021 - 서울관악 - 0984</p>
									<p>대표자 : 김희인</p>
								</div>
								<div className={styles.contact_part}>
									<h3>CONTACT US</h3>									
									<p>[ 스튜디오 ] 서울시 관악구 낙성대역4길 3, 4층</p>
									<p>전화 : 070-4422-8060</p>
									<p>이메일 : service@tryground.co.kr</p>
								</div>
								<div className={styles.sns_part}>
									<h3>FOLLOW US</h3>
									<a href='https://blog.naver.com/tryground/222756745495' target="_blank" rel="noopener noreferrer"><img src='/img/blog-icon.png' alt='naver-blog-icon'></img></a>
									<a href='https://www.instagram.com/tryground.studio'  target="_blank" rel="noopener noreferrer"><img src='/img/insta-icon.png' alt='insta-icon'></img></a>
									<a href='https://www.youtube.com/channel/UCeB5QsWqR5TVNu7pA6rLl7A' target="_blank" rel="noopener noreferrer"><img src='/img/youtube-icon.png' alt='youtube-icon'></img></a>
								</div>

							</div>

							<div className={styles.footer_bottom_part}>
								<div className={styles.copyright}>
									<h3>Copyright 2020. TRYGROUND all rights reserved.</h3>
								</div>
							</div>


						</div>
					</div>
        </>
    );
}

export default Footer